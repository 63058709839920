<template>
  <div>
    <div
      v-if="loading"
      :class="['spinner-container', { 'd-block': loading }]">
      <div
        class="archive-spinner-border"
        role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="wrapper mb-4">
      <row>
        <column xs="12">
          <h1
            v-text="__('filter.your.search')"
            class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          </h1>
        </column>
      </row>
      <row :style="loading && blurStyle">
        <column
          xs="12"
          md="3">
          <row>
            <column
              v-for="(facet, index) in facets"
              :key="'facet-' + index"
              md="12"
              xs="6">
              <component
                :is="facetComponent(facet)"
                :key="index"
                :facet="facet"
                @update="updateFacet"/>
            </column>
          </row>
        </column>
        <column
          xs="12"
          md="9">
          <fieldset class="form-group">
            <row>
              <column
                sm="6"
                xs="12"
                class="d-flex mb-3 mb-sm-0">
                <input
                  v-model="query.q"
                  :placeholder="__('search.placeholder')"
                  @keyup.enter="updateQuery"
                  type="text"
                  class="form-control border-right-0">
                <button
                  @click="updateQuery"
                  v-text="__('search')"
                  class="btn btn-primary btn-rounded-right">
                </button>
              </column>
              <column
                sm="3"
                xs="6">
                <select
                  v-model="query.sort"
                  @change="updateQuery"
                  class="form-control">
                  <option
                    v-text="__('relevance')"
                    value="reld"/>
                  <option
                    v-text="__('newest.first')"
                    value="aged"/>
                  <option
                    v-text="__('oldest.first')"
                    value="agea"/>
                </select>
              </column>
              <column
                sm="3"
                xs="6">
                <select
                  v-model="query.mode"
                  @change="updateQuery"
                  class="form-control">
                  <option
                    v-text="__('all.words')"
                    value="all"/>
                  <option
                    v-text="__('any.word')"
                    value="or"/>
                  <option
                    v-text="__('exact.phrase')"
                    value="phrase"/>
                </select>
              </column>
            </row>
          </fieldset>
          <row v-if="articles.length">
            <column xs="12">
              <ArchivePagination @update="updatePage"/>
            </column>
          </row>
          <row>
            <column xs="12">
              <template v-if="articles.length">
                <template v-for="(article) in articles">
                  <Teaser
                    :key="article.id"
                    :grid="[3,9]"
                    :teaser-element="article"
                    :show-lead-text="false"
                    :show-main-category="true"
                    :show-kicker="false"
                    :highlight-by-category="highlightByCategory"
                    image-size="small"
                    teaser-type="medium">
                    <template v-slot:footer>
                      <TeaserDetail
                        :date="new Date(article.publishedAt).toISOString()"
                        style-class="ff-flama fs-sm mt-2"
                        show-publish-prefix/>
                    </template>
                  </Teaser>
                </template>
              </template>
              <template v-else>
                <div
                  v-show="!loading"
                  v-text="__('no.articles.found', `${$route.query.q ? `for '${$route.query.q}'` : ''}`)"></div>
              </template>
            </column>
          </row>
          <row v-if="articles.length">
            <column xs="12">
              <ArchivePagination @update="updatePage"/>
            </column>
          </row>
        </column>
      </row>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import qs from 'qs';
import { createNamespacedHelpers } from 'vuex';
import { Page, ArchiveDateFacet, ArchiveFacet, ArchivePagination } from 'global-components';

const { mapState } = createNamespacedHelpers('search');
const blurWeight = '5px';
const blurStyle = {
  '-webkit-filter': `blur(${blurWeight})`,
  '-moz-filter': `blur(${blurWeight})`,
  '-ms-filter': `blur(${blurWeight})`,
  '-o-filter': `blur(${blurWeight})`,
  filter: `blur(${blurWeight})`,
};
const defaultSearch = {
  sort: 'aged',
  mode: 'all',
};
export default {
  name: 'archive-page',
  components: {
    /* eslint-disable vue/no-unused-components */
    ArchiveDateFacet,
    ArchiveFacet,
    /* eslint-enable vue/no-unused-components */
    ArchivePagination,
  },
  extends: Page,
  data() {
    return {
      loading: true,
      highlightByCategory: this.$store.state.publication.highlightByCategory,
    };
  },
  computed: {
    ...mapState({
      facets(state) {
        return state.content.facets;
      },
      articles(state) {
        return state.content.articles;
      },
      query(state) {
        return state.query;
      },
    }),
    blurStyle() {
      return blurStyle;
    },
  },
  watch: {
    $route() {
      this.doSearch();
    }
  },
  async beforeMount() {
    await this.doSearch();
    this.init();
  },
  methods: {
    init() {
      let { q, sort, mode, offset } = this.$route.query;
      const selectedFacets = this.facets.reduce((acc, facet) => {
        let facetQ = this.$route.query[facet.name];
        if (facet.multiSelect) {
          if (!facetQ) {
            facetQ = [];
          } else if (typeof facetQ === 'string') {
            facetQ = [facetQ];
          }
        }
        acc[facet.name] = facetQ;
        return acc;
      }, {});
      if (!sort) sort = defaultSearch.sort;
      if (!mode) mode = defaultSearch.mode;
      this.$store.commit('search/query', { q, sort, mode, ...selectedFacets });
      if (offset) {
        this.$store.commit('search/page', { offset });
      }
    },
    facetComponent(facet) {
      return facet.name === 'publishdate' ? 'ArchiveDateFacet' : 'ArchiveFacet';
    },
    updateFacet(query = {}) {
      query = this.cleanQuery(query);
      delete query.offset;
      this.updateQuery(query);
    },
    updatePage(query = { offset: 0 }) {
      query = this.cleanQuery(query);
      this.updateQuery(query);
    },
    updateQuery(query = {}) {
      if (query instanceof Event) {
        query = this.cleanQuery({});
        delete query.offset;
      }
      this.$store.commit('search/query', query);
      this.updateRoute(query);
    },
    updateRoute(query = {}) {
      this.$router.push({ query });
    },
    cleanQuery(query = {}) {
      const cleanQuery = { ...this.query };
      delete cleanQuery.size;
      delete cleanQuery.total;
      if (cleanQuery.q === '') delete cleanQuery.q;
      if (cleanQuery.offset === 0) delete cleanQuery.offset;
      if (cleanQuery.sort === defaultSearch.sort) delete cleanQuery.sort;
      if (cleanQuery.mode === defaultSearch.mode) delete cleanQuery.mode;
      return cleanQuery;
    },
    async doSearch() {
      this.loading = true;
      try {
        const qstring = qs.stringify(this.$route.query);
        const { data: { facets, articles, offset, size, total } } =
          await axios.get('/global/api/search?' + qstring);
        this.$store.commit('search/content', { facets, articles });
        this.$store.commit('search/page', { offset, size, total });
      } catch (e) {
        console.log('Search response error.', e);
      }
      this.loading = false;
    }
  }
};
</script>
